import React, { useState } from "react"
import { Container, Carousel } from "react-bootstrap"

import Image from "@components/common/image"

const FinanceSlider = () => {
  const [index, setIndex] = useState(0)

  // const firstCoverRef = React.useRef()
  // const secondCoverRef = React.useRef()

  // React.useEffect(() => {
  //   if (
  //     firstCoverRef &&
  //     firstCoverRef.current &&
  //     secondCoverRef &&
  //     secondCoverRef.current
  //   ) {
  //     firstCoverRef.current.addEventListener("sal:in", ({ detail }) => {
  //       console.log("called", { detail, index })
  //       handleCarouselItemClick(null, index === 1 ? 0 : 1)
  //     })

  //     firstCoverRef.current.addEventListener("sal:out", ({ detail }) => {
  //       console.log("out called", { detail, index })
  //       detail.target.classList.remove("sal-animate");
  //     })

  //     secondCoverRef.current.addEventListener("sal:in", ({ detail }) => {
  //       console.log("called", { detail, index })
  //       handleCarouselItemClick(null, index === 2 ? 1 : 2)
  //     })

  //     secondCoverRef.current.addEventListener("sal:out", ({ detail }) => {
  //       console.log("out2 called", { detail, index })
  //       detail.target.classList.remove("sal-animate");
  //     })
  //   }
  // }, [])

  function handleCarouselItemClick(e, i) {
    if (e) {
      e.preventDefault()
    }
    setIndex(i)
  }

  return (
    <section id="finance-slider" className="finance-slider-wrapper">
      <div className="content-container">
        <div className="finance-slider">
          <Container
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Carousel
              activeIndex={index}
              interval={1000}
              onSelect={i => setIndex(i)}
            >
              <Carousel.Item
                className={index === 1 ? "carousel-item-left2" : ""}
              >
                <h4>
                  <a
                    href="#finance-slider"
                    onClick={e =>
                      handleCarouselItemClick(e, index === 1 ? 0 : 1)
                    }
                  >
                    <span>
                      <Image src="finance-young.png" />
                    </span>
                    <span>$3 million</span>
                    <span>in funding rounds led</span>
                  </a>
                </h4>
              </Carousel.Item>
              <Carousel.Item
                className={
                  (index === 2 ? "carousel-item-left2" : "") +
                  (index === 0 ? "carousel-item-right2" : "")
                }
              >
                <h4>
                  <a
                    href="#finance-slider"
                    onClick={e =>
                      handleCarouselItemClick(
                        e,
                        index === 2 || index === 0 ? 1 : 2
                      )
                    }
                  >
                    <span>
                      <Image src="finance-dynamic.png" />
                    </span>
                    <span>$20+ million</span>
                    <span>total capital allocated</span>
                  </a>
                </h4>
              </Carousel.Item>
              <Carousel.Item
                className={index === 1 ? "carousel-item-right2" : ""}
              >
                <h4>
                  <a
                    href="#finance-slider"
                    onClick={e =>
                      handleCarouselItemClick(
                        e,
                        index === 1 || index === 0 ? 2 : 1
                      )
                    }
                  >
                    <span>
                      <Image src="finance-nontraditional.png" />
                    </span>
                    <span>20+</span>
                    <span>years of experience</span>
                  </a>
                </h4>
              </Carousel.Item>
            </Carousel>
          </Container>
        </div>
      </div>
      {/* <div
        ref={firstCoverRef}
        data-sal="fade"
        data-sal-delay="0"
        data-sal-easing="ease"
        className="content-cover d-none d-lg-flex"
      ></div>
      <div
        ref={secondCoverRef}
        data-sal="fade"
        data-sal-delay="0"
        data-sal-easing="ease"
        className="content-cover d-none d-lg-flex"
      ></div> */}
    </section>
  )
}

export default FinanceSlider

import React from "react"
import { Row, Col, Button } from "react-bootstrap"

import Image from "@components/common/image"
import scrollTo from "gatsby-plugin-smoothscroll"

const Intro = () => (
  <section className="container home-intro">
    <Row>
      <Col
        data-sal="slide-right"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="1000"
        lg={6}
        xl={5}
      >
        <h4>
          <span>Building the runway for game changing</span>
          <span>Entrepreneurs</span>
        </h4>
        <p>
          We go beyond providing growth capital, offering start-ups and
          entrepreneurs unlimited access to our extensive networks, expertise,
          and go-to- market resources.
        </p>

        <Button
          onClick={() => scrollTo("#finance-slider")}
          className="btn-primary"
        >
          <span>Learn More</span>
          <Image className="icon" src="down-arrow-icon.png" />
        </Button>
      </Col>
      <Col
        data-sal="slide-left"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="1000"
        lg={5}
        xl={4}
      >
        <Image src="flight-and-runway.png" />
      </Col>
    </Row>
  </section>
)

export default Intro

import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Link } from "gatsby"
import ArrowIcon from "../../../images/arrow-right.inline.svg";

const WhatWeDo = () => {
  return (
    <section className="what-we-do">
      <Container>
        <Row>
          <Col
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
            lg={6}
            xl={4}
          >
            <h4>
              What <span>we do</span>
            </h4>
            <p>
              We invest in early-stage and growing companies across a broad
              range of sectors – wherever we see the right opportunity. We
              identify teams capable of spearheading business innovation and
              entrepreneurship in their ventures, and we partner with them to
              spur growth.
            </p>
            <Link to="/who-we-are/" className="btn btn-secondary">Who We Are</Link>
          </Col>
          <Col
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
            lg={6}
            xl={5}
          >
            <div>
              <h3>Venture Capital</h3>
              <p>
                We deploy smart capital into early stage, scalable
                technology-enabled companies - Seed to Series A.
              </p>
              <Link to="/our-portfolio#our-portfolio" className="arrow-icon">
                <ArrowIcon />
              </Link>
            </div>
            <div>
              <h3>Private Equity</h3>
              <p>
                We invest in direct equity of promising small-to-medium
                enterprises - Ready to unlock growth.
              </p>
              <Link to="/our-portfolio#our-portfolio" className="arrow-icon">
                <ArrowIcon />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WhatWeDo

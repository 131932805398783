import React from "react"

import Layout from "@components/layout"
import SEO from "@components/common/seo"


import Intro from "@components/views/home/intro"
import FinanceSlider from "@components/views/home/finance-slider"
import OurVision from "@components/views/home/our-vision"
import WhatWeDo from "@components/views/home/what-we-do"
import PartnersTestimonial from "@components/views/home/partners-testimonial"
import GlobalNetwork from "@components/views/home/global-network"
import ApplyForFunding from "@components/views/home/apply-for-funding"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Intro/>
    <FinanceSlider/>
    <OurVision/>
    <WhatWeDo />
    <PartnersTestimonial />
    <GlobalNetwork/>
    <ApplyForFunding/>
  </Layout>
)

export default IndexPage

import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Image from "@components/common/image"

const GlobalNetwork = () => {
  const totalDots = 7
  const [dotActive, setDotActive] = React.useState(null)
  const globalNetworkRef = React.useRef()

  React.useEffect(() => {
    if (dotActive === null && globalNetworkRef && globalNetworkRef.current) {
      globalNetworkRef.current.addEventListener("sal:in", ({ details }) => {
        setDotActive(1)
      })
    }

    let timeout
    if (dotActive !== null) {
      timeout = setTimeout(() => {
        setDotActive(dotActive === totalDots ? 1 : dotActive + 1)
      }, 2000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [dotActive])

  return (
    <section
      ref={globalNetworkRef}
      data-sal="fade"
      data-sal-delay="0"
      data-sal-easing="ease"
      data-sal-duration="1000"
      className="global-network"
      id="global-network"
    >
      <Container>
        <Row>
          <Col lg={6}>
            <h4>
              Global Network of <span>Companies</span>
            </h4>
          </Col>
          <Col lg={6}>
            <p>
              Since its inception in 2014, Al Falaj Investment has built up an
              impressive track record of delivering value through its managing
              of venture capital and private equity investments with the support
              of its trusted partners across a range of sectors and geographies.
            </p>
          </Col>
        </Row>
        <div className="map">
          <div className="inner">
            <span
              className={dotActive === 1 ? "dot active" : "dot"}
              style={{ top: "36%", left: "19%" }}
            ></span>
            <span
              className={dotActive === 2 ? "dot active" : "dot"}
              style={{ top: "27%", left: "44%" }}
            ></span>
            <span
              className={dotActive === 3 ? "dot active" : "dot"}
              style={{ top: "27%", left: "48%" }}
            ></span>
            <span
              className={dotActive === 4 ? "dot active" : "dot"}
              style={{ top: "33%", left: "46%" }}
            ></span>
            <span
              className={dotActive === 5 ? "dot active" : "dot"}
              style={{ top: "48%", left: "59%" }}
            ></span>
            <span
              className={dotActive === 6 ? "dot active" : "dot"}
              style={{ top: "52%", left: "65%" }}
            ></span>
            <span
              className={dotActive === 7 ? "dot active" : "dot"}
              style={{ top: "49%", left: "70%" }}
            ></span>
            <Image src="global-network.png" alt="Global Network" />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default GlobalNetwork

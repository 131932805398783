import React from "react"

import { Col, Row, Container } from "react-bootstrap"
import Image from "@components/common/image"

const OurVision = () => {
  return (
    <div className="our-vision-wrapper">
      <Container className="content-container text-center">
        <Row>
          <Col xs={12}>
            <h2
              className="section-title"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              Our Vision
            </h2>
            <h3
              className="section-subtitle"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              To transform ambition into leadership and become the MENA region's
              foremost Venture Capital &amp; Private Equity investor
            </h3>
          </Col>
        </Row>
      </Container>
      <Container className="image-container">
        <Row>
          <Col xs={7} lg={12}>
            <Image src="mission-img-1.png" />
          </Col>
          <Col xs={5} lg={12}>
            <Image src="mission-img-2.png" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OurVision
